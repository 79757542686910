import React, { useContext, useEffect, useState } from 'react';
import './Header.css';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { getFromLocalStorage, removeFromLocalStorage } from '../../helpers/auth';
import { AuthContext } from '../../context/auth';
import Logo from '../../assets/images/hdrLogo.png';

export default function Header({ width }) {
	const navigate = useNavigate();
	const { auth, setAuth } = useContext(AuthContext);
	const [isValidToken, setIsValidToken] = useState(false);

	useEffect(() => {
		if (!auth || auth === null || auth === undefined) {
			setAuth(getFromLocalStorage('auth'));
		}
	}, [auth, setAuth]);

	const handleLogout = () => {
		setAuth(null);
		removeFromLocalStorage('auth');
		removeFromLocalStorage('_grecaptcha');
		navigate('/login');
	};

	async function handleReCaptchaVerify(token) {
		if (!token) {
			return;
		}

		token && setIsValidToken(true);
	}

	return (
		<>
			<GoogleReCaptcha onVerify={(token) => handleReCaptchaVerify(token)} />
			{/*}
			{isValidToken && (
			{*/}
			<>
				<div className='mb-3 d-flex flex-column justify-content-center align-items-center flex-lg-row justify-content-md-between align-items-md-center'>
					<div className='col-12 col-lg-4 d-flex justify-content-center justify-content-lg-start'>
						<Link to='/'>
							<img className='logo' src={Logo} alt='Zaxxiss' />
						</Link>
					</div>
					<div className='col-12 col-lg-4 d-flex justify-content-center'>
						<a className='phnText' href='tel:6025609299'>
							602.560.9299
						</a>
					</div>
					<div className='col-12 col-lg-4 d-flex justify-content-center justify-content-lg-end'>
						<Link className='hdrLink' to='/contact'>
							Request A Consultation
						</Link>
					</div>
				</div>
				<nav
					className='navbar sticky-top p-3 navbar-expand-lg d-flex flex-row justify-content-end'
					style={{ backgroundColor: 'rgba(85, 85, 85, 0.5)' }}
				>
					<div className='container-fluid d-flex justify-content-end'>
						<button
							className='custom-toggler navbar-toggler'
							type='button'
							data-bs-toggle='offcanvas'
							data-bs-target='#offcanvasNavbar'
							aria-controls='offcanvasNavbar'
						>
							<span className='custom-toggler navbar-toggler-icon'></span>
						</button>
					</div>
					<div className='offcanvas offcanvas-end' tabIndex='-1' id='offcanvasNavbar' aria-labelledby='offcanvasNavbarLabel'>
						<div className='offcanvas-header'>
							<button type='button' className='btn-close' data-bs-dismiss='offcanvas' aria-label='Close'></button>
						</div>
						{width >= 992 && (
							<>
								<div className='offcanvas-body'>
									<ul className='navbar-nav justify-content-end flex-grow-1 pe-3'>
										{(!auth || auth === null || auth === undefined) && (
											<>
												<li className='nav-item'>
													<Link className='navLink nav-link' to='/'>
														Home
													</Link>
												</li>
												<li className='nav-item'>
													<Link className='navLink nav-link' to='/login'>
														Login
													</Link>
												</li>
											</>
										)}
										{auth && (
											<>
												{auth.user.admin && (
													<>
														<li className='nav-item'>
															<Link className='navLink nav-link' to='/client'>
																Client
															</Link>
														</li>
														<li className='nav-item'>
															<Link className='navLink nav-link' to='/admin'>
																Admin
															</Link>
														</li>
													</>
												)}
												<li className='nav-item'>
													<div className='navLink nav-link' onClick={handleLogout}>
														Logout
													</div>
												</li>
											</>
										)}
									</ul>
								</div>
							</>
						)}
						{width <= 991 && (
							<div className='offcanvas-body' style={{ backgroundColor: '#000000' }}>
								<ul className='navbar-nav justify-content-end flex-grow-1 pe-3'>
									{(!auth || auth === null || auth === undefined) && (
										<>
											<li className='nav-item'>
												<Link className='navLink nav-link' to='/'>
													Home
												</Link>
											</li>
											<li className='nav-item'>
												<Link className='navLink nav-link' to='/login'>
													Login
												</Link>
											</li>
										</>
									)}
									{auth && (
										<>
											{auth.user.admin && (
												<>
													<li className='nav-item'>
														<Link className='navLink nav-link' to='/client'>
															Client
														</Link>
													</li>
													<li className='nav-item'>
														<Link className='navLink nav-link' to='/admin'>
															Admin
														</Link>
													</li>
												</>
											)}
											<li className='nav-item'>
												<div className='navLink nav-link' onClick={handleLogout}>
													Logout
												</div>
											</li>
										</>
									)}
								</ul>
							</div>
						)}
					</div>
				</nav>
			</>
			{/*}
			)}
			{*/}
		</>
	);
}
