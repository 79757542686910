import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { saveInLocalStorage } from '../../../helpers/auth';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import Input from '../../../components/form/Input';
import Button from '../../../components/form/Button';
import Password from '../../../components/form/Password';
import Spinner from '../../../components/spinner/Spinner';

export default function Login() {
	const navigate = useNavigate();
	const { setAuth } = useContext(AuthContext);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isValidToken, setIsValidToken] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const { data } = await axios.post('/signin', {
				email,
				password,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				setAuth(data);
				saveInLocalStorage('auth', data);
				navigate('/client');
			}
		} catch (err) {
			console.log(err);
		}
		setLoading(false);
	};

	async function handleReCaptchaVerify(token) {
		if (!token) {
			return;
		}

		token && setIsValidToken(true);
	}

	return (
		<div className='mt-5 d-flex flex-row justify-content-center'>
			<div className='col-10 col-md-6 col- col-lg-3'>
				<h2 className='pgHdng'>Login</h2>
				<form onSubmit={handleSubmit}>
					<GoogleReCaptcha onVerify={(token) => handleReCaptchaVerify(token)} />
					{/*}
					{isValidToken && (
					{*/}
					<>
						<Input name='email' id='email' label='Email' type='email' value={email} setValue={setEmail} />
						<Input name='password' id='password' label='Password' type='password' value={password} setValue={setPassword} />
						<div className='mt-4 d-flex justify-content-center'>
							<Button type='submit' disabled={!email || !password}>
								Login
							</Button>
						</div>
						<div className='my-3 d-flex justify-content-center'>
							<Password />
						</div>
					</>
					{/*}
					)}
					{*/}
				</form>
				{loading && <Spinner />}
			</div>
		</div>
	);
}
