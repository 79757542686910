import React, { useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-hot-toast';
import validator from 'validator';
import emailjs from '@emailjs/browser';
import Input from '../../../components/form/Input';
import Button from '../../../components/form/Button';
import BotTest from '../../../components/form/BotTest';
import Spinner from '../../../components/spinner/Spinner';

const formatPhoneNumber = (value) => {
	if (!value) return value;
	let phoneNumber = value.replace(/[^\d]/g, '');
	const phoneNumberLength = phoneNumber.length;

	if (phoneNumberLength < 4) return phoneNumber;

	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	if (phoneNumberLength > 10) {
		phoneNumber = phoneNumber.substring(0, 10);
	}

	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export default function Contact() {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [address, setAddress] = useState('');
	const [org, setOrg] = useState('');
	const [title, setTitle] = useState('');
	const [time, setTime] = useState('');
	const [msg, setMsg] = useState('');
	const [isValidToken, setIsValidToken] = useState(false);
	const [loading, setLoading] = useState(false);

	function handleSubmit(e) {
		e.preventDefault();
		setLoading(true);
		if (name.trim() === '') {
			const input = document.getElementById('name');
			if (input != null) {
				input.style.backgroundColor = '#fac7ca';
				input.style.border = '2px solid black';
			}
			toast.error('Name is Required');
			return;
		}
		if (email.trim() === '' || !validator.isEmail(email)) {
			const input = document.getElementById('email');
			if (input != null) {
				input.style.backgroundColor = '#fac7ca';
				input.style.border = '2px solid black';
			}
			toast.error('Please enter a valid Email');
			return;
		}
		if (phone.trim() === '') {
			const input = document.getElementById('phone');
			if (input != null) {
				input.style.backgroundColor = '#fac7ca';
				input.style.border = '2px solid black';
			}
			toast.error('Phone is Required');
			return;
		}
		if (org.trim() === '') {
			const input = document.getElementById('org');
			if (input != null) {
				input.style.backgroundColor = '#fac7ca';
				input.style.border = '2px solid black';
			}
			toast.error('Organization Name is Required');
			return;
		}
		if (time.trim() === '') {
			const input = document.getElementById('time');
			if (input != null) {
				input.style.backgroundColor = '#fac7ca';
				input.style.border = '2px solid black';
			}
			toast.error('Best time to reach you is Required');
			return;
		}
		if (msg.trim() === '') {
			const input = document.getElementById('msg');
			if (input != null) {
				input.style.backgroundColor = '#fac7ca';
				input.style.border = '2px solid black';
			}
			toast.error('Message is Required');
			return;
		}

		if (address === '') {
			//Send email to me
			const toSend = {
				fromName: name,
				fromEmail: email,
				fromPhone: phone,
				fromOrg: org,
				fromTitle: title,
				bestTime: time,
				msg: msg,
			};
			emailjs.send('service_gry8cdt', 'template_p8akvcg', toSend, 'Y3PCVvn8qu3sFfnw-');
		}
		setName('');
		setEmail('');
		setPhone('');
		setOrg('');
		setTitle('');
		setTime('');
		setMsg('');
		setLoading(false);
		toast.success('Thank you. Your request has been received.');
	}

	function handlePhone(e) {
		const value = e.target.value;
		const formattedPhoneNumber = formatPhoneNumber(value);
		setPhone(formattedPhoneNumber);
	}

	function handleTime(e) {
		const value = e.target.value;
		setTime(value);
	}

	function handleMessage(e) {
		const value = e.target.value;
		setMsg(value);
	}

	async function handleReCaptchaVerify(token) {
		if (!token) {
			return;
		}

		token && setIsValidToken(true);
	}

	return (
		<div className='mt-5 d-flex flex-row justify-content-center'>
			<div className='col-10 col-md-6 col-lg-3'>
				<h2 className='pgHdng'>Contact Us</h2>
				<form onSubmit={handleSubmit}>
					<GoogleReCaptcha onVerify={(token) => handleReCaptchaVerify(token)} />
					{/*}
					{isValidToken && (
					{*/}
					<>
						<Input name='name' id='name' label='Full Name' type='text' value={name} setValue={setName} />
						<Input name='email' id='email' label='Email Address' type='email' value={email} setValue={setEmail} />
						<Input name='phone' id='phone' label='Phone #' type='tel' value={phone} funcCall={handlePhone} />
						<BotTest name='add' id='add' label='Address' type='text' value={address} setValue={setAddress} />
						<Input name='org' id='org' label='Organization' type='text' value={org} setValue={setOrg} />
						<Input name='title' id='title' label='Title' type='text' value={title} setValue={setTitle} />
						<div className='mb-3'>
							<label className='frmLabel'>Best time to reach you</label>
							<select
								className='form-select'
								id='time'
								value={time}
								onChange={(e) => handleTime(e)}
								style={{ backgroundColor: '#2e2e2e', color: '#c9c9c9', border: '2px solid black' }}
							>
								<option defaultValue=''>Select One...</option>
								<option value='Morning'>Morning</option>
								<option value='Afternoon'>Afternoon</option>
								<option value='Evening'>Evening</option>
							</select>
						</div>
						<div className='mb-3'>
							<label className='frmLabel'>Message</label>
							<textarea
								className='form-control'
								id='msg'
								value={msg}
								onChange={(e) => handleMessage(e)}
								style={{ backgroundColor: '#2e2e2e', color: '#c9c9c9', border: '2px solid black' }}
							/>
						</div>
						<div className='mt-4 d-flex justify-content-center'>
							<Button type='submit' disabled={!name || !email || !phone || !org || !time || !msg}>
								Send Request
							</Button>
						</div>
					</>
					{/*}
					)}
					{*/}
				</form>
				{loading && <Spinner />}
			</div>
		</div>
	);
}
